import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import lampadina from '../../../assets/images/lampadina.png';

import './Intro.css';

const intro = (props) => {

	//Calculate if intro button is disabled
	var introDisabled = true;
	if (props.sesso && (props.eta > 0) && props.status_occupazionale) {
		introDisabled = false;
	}

	return (
		<Container className="Intro">
			<Row>
				<Col>
				<p>
				Sono le competenze delle persone a fare la differenza nella vita e nel lavoro. In particolare, come sostiene l’UE, le competenze chiave sono "quelle di cui tutti hanno bisogno per la realizzazione e lo sviluppo personale, l'occupabilità, l'inclusione sociale, uno stile di vita sostenibile, una vita fruttuosa in società pacifiche, una gestione della vita attenta alla salute e la cittadinanza attiva”. 
				<br></br>
				Le competenze si sviluppano attraverso l’apprendimento permanente, dalla prima infanzia a tutta la vita adulta, in tutti i contesti: famiglia, scuola, luogo di lavoro, le comunità. Utilizzando questo strumento di analisi delle tue competenze, verificheremo le aree di miglioramento in relazione alle variabili analizzate e ti consiglieremo sulle attività formative più idonee per te.
				</p>

				<br></br>

					<p>Questo quiz è un processo per misurare le tue competenze in merito a:</p>
					<ul>
						<li>te stesso</li>
						<li>le tue abilità e conoscenze</li>
						<li>la tua produttività</li>
						<li>la tua capacità di transizione</li>
					</ul>

					<p>Il gioco indaga 5 aree:</p>
					<ul>
						<li><b>Personal effectiveness</b> Efficacia personale </li>
						<li><b>Managing relationships</b> Gestire le relazioni </li>
						<li><b>Accessing life long learning</b> Accedere alle opportunità di aggiornamento professionale </li>
						<li><b>Managing life and career</b> Gestire la propria vita e la carriera </li>
						<li><b>Understanding the world</b> Comprendere il mondo</li>
					</ul>

					<p> <img src={lampadina} alt="lampadina" /> Accanto ad alcune delle domande visualizzerai una lampadina che ti fornirà un approfondimento sull’item. Non esistono risposte giuste o sbagliate.</p>

					<hr></hr>

					<h1> Sei pronto? </h1>

					<h5> Rilassati e inizia! </h5>

					<hr></hr>

					<p> Prima di iniziare dicci chi sei </p>

					<hr></hr>

					<Form.Group>
						<Form.Label>Genere</Form.Label>
						<Form.Control as="select" onChange={props.selectChanged} id="sesso" defaultValue={'DEFAULT'} >
							<option value="DEFAULT" disabled>Scegli un opzione</option>
							<option value="uomo">Uomo</option>
							<option value="donna">Donna</option>
						</Form.Control>
					</Form.Group>

{/*
					<Form.Group>
						<Form.Label>Ruolo</Form.Label>
						<Form.Control as="select" onChange={props.selectChanged} id="status_occupazionale" defaultValue={'DEFAULT'} >
							<option value="DEFAULT" disabled>Scegli un opzione</option>
							<option value="operaio">Operaio</option>
							<option value="impiegato">Impiegato</option>
							<option value="dirigente">Dirigente</option>
							<option value="altro">Altro</option>
						</Form.Control>
					</Form.Group>
*/}
					<Form.Group>
						<Form.Label>Attuale stato occupazionale</Form.Label>
						<Form.Control as="select" onChange={props.selectChanged} id="status_occupazionale" defaultValue={'DEFAULT'} >
							<option value="DEFAULT" disabled>Scegli un opzione</option>
							<option value="occupato">Occupato/a</option>
							<option value="inoccupato">Inoccupato/a – disoccupato/a</option>
							<option value="inmobilita">In mobilità/in NASPI</option>
							<option value="rdc">Percettore di RdC</option>
							<option value="domestico">Lavoratore/lavoratrice domestico/a</option>
						</Form.Control>
					</Form.Group>


					<Form.Group>
						<Form.Control min="1" max="100" value={props.eta} type="number" placeholder="Inserisci la tua età" onChange={props.etaChanged} />
					</Form.Group>

					<hr></hr>

					<button className="ds-button" onClick={props.startQuiz} disabled={introDisabled}>
						Iniziamo
					</button>
				</Col>
			</Row>
		</Container>
	);

}

export default intro;
