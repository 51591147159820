import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import lampadina from '../../../assets/images/lampadina.png';

import './IntroCompany.css';

const introcompany = ( props ) => {
	
	return (
		<Container className="IntroCompany">
			<Row>
				<Col>

					<p> Questo quiz è offerto da: </p>
					<h3> {props.nome_azienda} </h3>
					<p>Questo quiz è un processo per misurare le tue competenze merito a:</p>
					<ul>
						<li>te stesso</li>
						<li>le tue abilità e conoscenze</li>
						<li>la tua produttività</li>
						<li>la tua capacità di transizione</li>
					</ul>

					<p>Il gioco indaga 5 aree:</p>
					<ul>
						<li><b>Personal effectiveness</b> Efficacia personale </li>
						<li><b>Managing relationships</b> Gestire le relazioni </li>
						<li><b>Accessing life long learning</b> Accedere alle opportunità di aggiornamento professionale </li>
						<li><b>Managing life and career</b> Gestire la propria vita e la carriera </li>
						<li><b>Understanding the world</b> Comprendere il mondo.</li>
					 </ul>

					<p> <img src={lampadina} alt="lampadina"/> Accanto ad alcune delle domande visualizzerai una lampadina che ti fornirà un approfondimento sull’item. Non esistono risposte giuste o sbagliate.</p>

					<hr></hr>

					<h1> Sei pronto? </h1>

					<h5> Rilassati e inizia! </h5>
					
					<hr></hr>
				    
				    <button className="ds-button" onClick={props.startQuiz}>
						Iniziamo
					</button>
				</Col>
			</Row>
		</Container>
	);

}

export default introcompany;