import React from 'react';

import logoiterego from '../../assets/images/logoiterego.png';
import logoepar from '../../assets/images/logoepar.png';

import './Footer.css';

const footer = ( props ) => (
   	<div className="Footer">
   		
   		<div className="footerLabel">
   			<p> <b>AppForUp</b> è un progetto di </p>
   		</div>
   		<div>
   			<img src={logoepar} alt="Logo Epar"/>
   		</div>
   		<div>
   			<img src={logoiterego} alt="Logo Iterego"/>
   		</div>

    </div>
);
export default footer;