import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';

//Sub Components
import Backdrop from '../../../UI/Backdrop/Backdrop';

//Images
import brain from '../../../assets/images/brain.png';
import logoiterego from '../../../assets/images/logoiterego.png';
import logoepar from '../../../assets/images/logoepar.png';

//Style
import './MobileMenu.css';

/*
    MobileMenu
    This component render the side mobile menu
*/
const mobileMenu = ( props ) => {
    let attachedClasses = ["MobileMenu", "Close"];
    if (props.open) {
        attachedClasses = ["MobileMenu", "Open"];
    }

    let userNavItem = null;
    if (!props.appstate.isLoggedIn) {
        userNavItem = <NavLink to="/entra" exact activeClassName="active" onClick={props.closed}>Entra</NavLink>;
    } else {
        if (props.appstate.user.role === 3) {
            userNavItem = <NavLink to="/pannellodipendente" exact activeClassName="active" onClick={props.closed}>Pannello</NavLink>;
        } else {
            userNavItem = <NavLink to="/pannelloazienda" exact activeClassName="active" onClick={props.closed}>Pannello</NavLink>;
        }
    }

    return (
        
        <Auxiliary>
            
            <Backdrop show={props.open} clicked={props.closed}/>

            <div className={attachedClasses.join(' ')}>
                <div className="Logo">
                    <Link to="/">
                        <img src={brain} alt="DipendenteSuper Logo" />
                    </Link>
                </div>
                <nav>
                    <NavLink 
                        to="/" 
                        exact 
                        activeClassName="active"
                        onClick={props.closed}>Home</NavLink>
                    <NavLink 
                        to="/chisiamo" 
                        exact 
                        activeClassName="active"
                        onClick={props.closed}>Chi Siamo</NavLink>
                    <NavLink 
                        to="/perleaziende" 
                        exact 
                        activeClassName="active"
                        onClick={props.closed}>Per le aziende</NavLink>
                    <NavLink 
                        to="/privacy" 
                        exact 
                        activeClassName="active"
                        onClick={props.closed}>Privacy</NavLink>

                    {userNavItem}
                </nav>

                <div className="mobileMenuLabel">
                    <p> <b>AppForUp</b> è un progetto di </p>
                    <img src={logoepar} alt="Logo Epar"/>
                    <img src={logoiterego} alt="Logo Iterego"/>
                </div>

            </div>
        </Auxiliary>
    );
};

export default mobileMenu;