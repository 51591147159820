import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import facebook from '../../assets/images/facebook.png';
import whatsapp from '../../assets/images/whatsapp.png';
import linkedin from '../../assets/images/linkedin.png';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton
} from 'react-share';

import './About.css';

const about = ( props ) => (
   	<Container className="About">
		<Row>
		 	<Col xs="12">
		 		<h3>Chi siamo</h3>

		 		<p> <b>AppForUp</b> è un progetto di <a href="https://www.epar.it"><b>Epar</b></a> e <a href="https://www.iterego.it/"><b>IterEgo</b></a> finanziato dal fondo Fonarcom. </p>

		 		<p> <b>Epar</b> è l’Ente Bilaterale di riferimento di <b>CIFA</b> e <b>CONFSAL</b>. Dal 2012 Epar lavora per garantire alle aziende italiane prestazioni sociali a sostegno dei lavoratori,  tali da creare un contesto organizzativo più efficiente ed efficace, con la consapevolezza che il lavoratore è al centro della performance dell’azienda. </p>
		   		<p> <b>IterEgo</b> è agenzia formativa che eroga, a livello nazionale, progetti di formazione continua per i lavoratori delle aziende, con modalità innovative e tematiche in costante aggiornamento. </p>

				<p> Vuoi conoscere meglio i nostri servizi? <a href="mailto:info@iterego.it?subject=Richiesta%20Informazioni%20AppForUp">Contattaci</a> e scopri come possiamo aiutarti. </p>

		 		<h5>Fai conoscere AppForUp ai tuoi amici e colleghi</h5>
		 	</Col>

		 	<Col xs="12" className="shareButtons">
		   		<FacebookShareButton url="www.appforup.it">
		   			<img src={facebook} alt="Facebook share icon" />
		   		</FacebookShareButton>

		   		<WhatsappShareButton url="www.appforup.it">
		   			<img src={whatsapp} alt="Whatsapp share icon" />
		   		</WhatsappShareButton>

		   		<LinkedinShareButton url="www.appforup.it">
		   			<img src={linkedin} alt="Linkedin share icon" />
		   		</LinkedinShareButton>
		   	</Col>
		</Row>
	</Container>
);

export default about;