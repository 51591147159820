import React, {Component} from 'react';
import {Container, Row, Col, Form, Alert} from 'react-bootstrap';
import './Signin.css';

class Signin extends Component {

	constructor(props) {
	    super(props);

	    this.initialState = {
	     	email: "", //Store form value email
	      	password: "", //Store form value password
	      	isLoading: false,
	      	displayAlert: false
	    };

	    this.state = this.initialState; //Store the initial state, so it can be restored
	}

	componentDidMount(){

		//If User is already logged, move to panel:
		if (this.props.appstate.isLoggedIn) {
			//Check user role:
			if (this.props.appstate.user.role === 3) {
				//Send to dipendente dashboard
		    	this.props.history.replace('pannellodipendente');
			} else if (this.props.appstate.user.role === 4) {
				//Send to company dashboard
		    	this.props.history.replace('pannelloazienda');
			}
		}
	}

	//handleChange
	//handle form change (email, password)
	handleChange = event => {
		this.setState({
	    	[event.target.type]: event.target.value,
	    	displayAlert: false
	    });
	}

	//Process submit:
    handleSubmit = async event => {
    	event.preventDefault();

	    this.setState({ isLoading: true });

	    this.props.signIn(this.state.email, this.state.password, (success) => {
	    	if (success) {
	    		//Check user role:
				if (this.props.appstate.user.role === 3) {
					//Send to dipendente dashboard
			    	this.props.history.replace('pannellodipendente');
				} else if (this.props.appstate.user.role === 4) {
					//Send to dipendente dashboard
			    	this.props.history.replace('pannelloazienda');
				}
	    	} else {
	    		this.setState({ 
	    			displayAlert: true,
	    			email: "",
	    			password: ""
	    		});
	    	}
	    });
  	}

	render() {

		//Check if display alert:
		let alert = null;
		if (this.state.displayAlert) {
			alert = (
				<Alert variant="danger">
					La combinazione Email Password è errata, o l'email non è verificata.
				</Alert>
			);
		}

		//Check if disable submit button
		let submitDisabled = true;
		if (
			this.state.email &&
			this.state.password
		) {
			submitDisabled = false;
		}

	    return (
	    	<Container className="Signin">
				<Row>
			    	<Col>
			    		<h3> Benvenuto </h3>
						
						<Form onSubmit={this.handleSubmit}>
						  <Form.Group controlId="formBasicEmail">
						    <Form.Label>Email</Form.Label>
						    <Form.Control type="email" placeholder="Inserisci Email" value={this.state.email} onChange={this.handleChange}/>
						  </Form.Group>

						  <Form.Group controlId="formBasicPassword">
						    <Form.Label>Password</Form.Label>
						    <Form.Control type="password" placeholder="Inserisci Password" value={this.state.password} onChange={this.handleChange}/>
						  </Form.Group>

						  {alert}

						  <button className="ds-button" type="submit" disabled={submitDisabled}>
						    Entra
						  </button>
						</Form>
			    	</Col>
				</Row>
			</Container>
		);
	}

}

export default Signin;