import React, {Component} from 'react';
import {Container, Row, Col, Form, Alert} from 'react-bootstrap';

import './Signup.css';

class Signup extends Component {

	constructor(props) {
	    super(props);

	    this.initialState = {
	    	
	    	//User info
	    	nome: "",
	    	cognome: "",

	     	email: "",
	      	repeatEmail: "",

	      	password: "",
	      	repeatPassword: "",

	      	pin: "",
			
			//Company info	     	
	    	ragione_sociale: "",
	    	partita_iva: "",
	    	numero_dipendenti: "",

	    	//Flag Phases
	      	isLoading: false,
	      	signupCompleted: false,

	      	//Form error
	      	displayError: false,
	      	errorMessage: ""
	    };

	    this.state = this.initialState; //Store the initial state, so it can be restored
	}

	//handleChange
	//handle form change (email, password)
	handleChange = event => {
		this.setState({
	    	[event.target.id]: event.target.value,
	    	displayError: false
	    });
	}

	//Process submit:
    handleSubmit = async event => {
    	event.preventDefault();

	    this.setState({ isLoading: true });

	    //Check for form errors:
	    if (!validateEmail(this.state.email)) {

	    	console.log("Form error: email invalid");
	    	this.setState({
	    		email: "",
	    		repeatEmail: "",
	    		displayError: true,
	    		errorMessage: "Email non valida. Riprova."
	    	});

	    } else if (this.state.email !== this.state.repeatEmail) {

	    	console.log("Form error: email not equals");
	    	this.setState({
	    		email: "",
	    		repeatEmail: "",
	    		displayError: true,
	    		errorMessage: "Email e Ripeti Email non corrispondono. Riprova."
	    	});

	    } else if (this.state.password.length < 8){

	    	console.log("Form error: Password < 8 chars");
	    	this.setState({
	    		password: "",
	    		repeatPassword: "",
	    		displayError: true,
	    		errorMessage: "La Password deve essere lunga almeno 8 caratteri. Riprova."
	    	});

	    } else if (this.state.password !== this.state.repeatPassword){

	    	console.log("Form error: Passwords different");
	    	this.setState({
	    		password: "",
	    		repeatPassword: "",
	    		displayError: true,
	    		errorMessage: "Password e Ripeti Password non coincidono. Riprova."
	    	});

	    } else if (this.state.partita_iva.length !== 11){

	    	console.log("Form error: partita not 11 chars");
	    	this.setState({
	    		partita_iva: "",
	    		displayError: true,
	    		errorMessage: "La Partita Iva deve contenere 11 numeri. Riprova."
	    	});

	    } else {
	    	//Everythings ok.
	    	//Submit.
	    	this.props.signUp(

	    	this.state.nome,
	    	this.state.cognome,
	    	this.state.email, 
	    	this.state.password,
	    	this.state.ragione_sociale,
	    	this.state.partita_iva,
	    	this.state.numero_dipendenti,

	    	success => {
	    		if (success) {
	    			this.setState({
			    		isLoading: false,
			    		signupCompleted: true
			    	});	
	    		} else {
	    			//Show error
	    		}
		    }
		);
	    }   
  	}

  	handleVerifyPin = () => {

		//Call App.js verifyPin function
		this.props.verifyPin(this.state.pin, success => {

			console.log(success);

			//If success, redirect to results
			if (success) {
				this.props.history.replace('pannelloazienda');
			} else {
				this.setState({
		            displayError: true,
		            errorMessage: "Errore durante la verifica. Riprova"
		        });
			}
		});
	}

	render() {

		//Check if disable submit button
		let submitDisabled = true;
		if (
			this.state.nome &&
			this.state.cognome && 
			this.state.email &&
			this.state.repeatEmail &&
			this.state.password &&
			this.state.repeatPassword &&
			this.state.ragione_sociale &&
			this.state.partita_iva &&
			this.state.numero_dipendenti
		) {
			submitDisabled = false;
		}

		let error = null;
		if (this.state.displayError) {
			error = <Alert variant="danger"> {this.state.errorMessage}</Alert>
		}

	    return (
	    	<Container className="Signup">

	    		{this.state.signupCompleted?
					<Row>
						<Col className="verify">
							<h3> Registrazione effettuata correttamente.</h3>
							<p> Inserisci qui sotto il pin che ti abbiamo inviato alla seguente email: <b>{this.state.email}</b> </p>
							<input id="pin" type="text" placeholder="Esempio: abcd" value={this.state.pin} onChange={this.handleChange}/>
							{error}
							<button className="ds-button" onClick={this.handleVerifyPin} disabled={!this.state.pin}> Conferma </button>
						</Col>
					</Row>
				:
					<Row>
				    	<Col>
				    		<h3> Registrazione Azienda </h3>

				    		<p> Tutti i campi sono obbligatori </p>

				    		{error}
							
							<Form onSubmit={this.handleSubmit}>
								
								<hr></hr>

								<span> I TUOI DATI </span>

								<Form.Group>
									<Form.Label>Nome</Form.Label>
							    	<Form.Control id="nome" type="text" placeholder="" value={this.state.nome} onChange={this.handleChange}/>
							 	</Form.Group>

							 	<Form.Group>
									<Form.Label>Cognome</Form.Label>
							    	<Form.Control id="cognome" type="text" placeholder="" value={this.state.cognome} onChange={this.handleChange}/>
							 	</Form.Group>

							 	<Form.Group>
									<Form.Label>Email</Form.Label>
							    	<Form.Control id="email" type="email" placeholder="Esempio: latua@email.it" value={this.state.email} onChange={this.handleChange}/>
							 	</Form.Group>

								<Form.Group>
									<Form.Label>Ripeti Email</Form.Label>
							    	<Form.Control id="repeatEmail" type="email" placeholder="Esempio: latua@email.it" value={this.state.repeatEmail} onChange={this.handleChange}/>
							 	</Form.Group>

							 	<Form.Group>
									<Form.Label>Password</Form.Label>
							    	<Form.Control id="password" type="password" placeholder="" value={this.state.password} onChange={this.handleChange}/>
							    	<Form.Text className="text-muted">Inserisci una password di almeno 8 caratteri</Form.Text>
							 	</Form.Group>

							 	<Form.Group>
									<Form.Label>Ripeti Password</Form.Label>
							    	<Form.Control id="repeatPassword" type="password" placeholder="" value={this.state.repeatPassword} onChange={this.handleChange}/>
							 	</Form.Group>

							 	<hr></hr>

							 	<span> LA TUA AZIENDA </span>

							 	<Form.Group>
									<Form.Label>Ragione Sociale</Form.Label>
							    	<Form.Control id="ragione_sociale" type="text" placeholder="Esempio: Rossi Srl" value={this.state.ragione_sociale} onChange={this.handleChange}/>
							 	</Form.Group>

							 	<Form.Group>
									<Form.Label>Partita IVA</Form.Label>
							    	<Form.Control id="partita_iva" type="number" placeholder="Esempio: 00000000000" value={this.state.partita_iva} onChange={this.handleChange}/>
							    	<Form.Text className="text-muted">Inserisci gli 11 numeri della tua partita iva</Form.Text>
							 	</Form.Group>

							 	<Form.Group>
									<Form.Label>Numero Dipendenti</Form.Label>
							    	<Form.Control id="numero_dipendenti" type="number" placeholder="Esempio: 13" value={this.state.numero_dipendenti} onChange={this.handleChange}/>
							 	</Form.Group>

							 	<hr></hr>

							 	{error}

							  	<button className="ds-button" type="submit" disabled={submitDisabled}>
							    	Iscriviti
							  	</button>
							</Form>
				    	</Col>
					</Row>
				}
			</Container>
		);
	}

}

export default Signup;

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}