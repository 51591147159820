import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import uomo from '../../assets/images/uomo.png';
import donna from '../../assets/images/donna.png';
import brain from '../../assets/images/brain.png';

import './Home.css';

const home = (props) => {

	return (
		<Container className="Home">
			<Row>

				<Col xs="12" md="8">
					<h3> Sei un lavoratore o in cerca di lavoro? </h3>
					<p> Fai il quiz e testa le tue<br></br> <b>competenze lavorative</b></p>
					<button onClick={() => props.history.push("quiz")} id="dipendente-btn">
						<img src={donna} alt="Icona Donna" />
						<label>Iniziamo</label>
					</button>
				</Col>
				
			
				
				<Col md="4" className="d-none d-sm-block">
					<img src={brain} alt="Icona Superman" id="brain" />
				</Col>



			</Row>
		</Container>
	);
}

export default home;