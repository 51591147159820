import React from 'react';
import { NavLink, Link } from 'react-router-dom';

//Images
import burgerimg from '../../../assets/images/burger.png';
import logo from '../../../assets/images/logo.png';

//Style
import './Topbar.css';

/*
    Topbar
    This component render:
        - Desktop navigation topbar
        - Logo
        - Burger Button (toggle the MobileMenu)
*/

const topbar = ( props ) => {
    
    let userNavItem = null;
    if (!props.appstate.isLoggedIn) {
        userNavItem = <NavLink to="/entra" exact activeClassName="active">Entra</NavLink>;
    } else {
        if (props.appstate.user.role === 3) {
            userNavItem = <NavLink to="/pannellodipendente" exact activeClassName="active">Pannello Dipendente</NavLink>;
        } else {
            userNavItem = <NavLink to="/pannelloazienda" exact activeClassName="active">Pannello Azienda</NavLink>;
        }
    }

    return(
        <header className="Topbar">
            
            <div className="Logo">
                <Link to="/">
                    <img src={logo} alt="DipendenteSuper Logo" />
                </Link>
            </div>

            <button className="Burger" onClick={props.mobileMenuToggleClicked}> 
                <img src={burgerimg} alt="DipendenteSuper Menu"/> 
            </button>

            <nav>
                {userNavItem}
                <NavLink to="/chisiamo" exact activeClassName="active">Chi Siamo</NavLink>
                <NavLink to="/perleaziende" exact activeClassName="active">Per le aziende</NavLink>
                <NavLink to="/privacy" exact activeClassName="active">Privacy</NavLink>
            </nav>

        </header>
    );
}

export default topbar;