import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import loading from '../../assets/images/loading.gif';

//API lib
import api from "../../api";

import './EmployeePanel.css';

class EmployeePanel extends Component {

	constructor(props){
		super(props);

		this.initialState = {
			quizzesCompleted: null
		}
		this.state = this.initialState; //Store the initial state, so it can be restored
	}

	componentDidMount(){
		//Get user info from backend
		//Get the quiz from API
		api.get('quizzes/user', {
		    params: {token: this.props.appstate.token}
		})
		.then(res => {
			this.setState({
				quizzesCompleted: res.data.data.quizzes
			});
		});
	}

	handleClickSignout = () => {
		this.props.signOut(success => {
	    	if (success) {
	    		//redirect
	    		this.props.history.replace('entra');
	    	}
	    });
	}

	handleClickQuiz(id) {
	    this.props.history.replace('risultati?quiz_id=' + id);
	}

	render(){


		let quizzesCompleted_dom = <img id="loadingIcon" src={loading} alt="Loading Icon"/>;
		if (this.state.quizzesCompleted) {
			quizzesCompleted_dom = this.state.quizzesCompleted.map(quiz => {
				return(
					<div className="quizPreview" key={quiz.quiz.id}>
						<span className="quizName"> {quiz.quiz.name} </span>
						<button onClick={() => this.handleClickQuiz(quiz.quiz.id)}> Vai ai risultati </button>
					</div>
				);
			});
		}

		//Display Loading at start
		if (this.props.appstate.user) {
			return(
				<Container className="EmployeePanel">
					<Row>

					 	<Col xs="12" className="employeeInfo">
					 		<h3>Pannello Dipendente</h3>
					 		<p><b>Nome</b>: {this.props.appstate.user.nome}</p>
					 		<p><b>Cognome</b>: {this.props.appstate.user.cognome}</p>
					 		<p><b>Email</b>: {this.props.appstate.user.email}</p>
					 		<button onClick={this.handleClickSignout}> Esci </button>
					   	</Col>

					   	<Col xs="12">
					 		<h3>Quiz Completati:</h3>
					 		{quizzesCompleted_dom}
					   	</Col>

					</Row>
				</Container>
			);
		} else {
			return(null)
		}

	}

	
}

export default EmployeePanel;