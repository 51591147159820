import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import './Privacy.css';

const privacy = ( props ) => (
   	<Container className="Privacy">
		<Row>
		 	<Col xs="12">
				<h2> Informativa estesa sulla privacy di AppForUp </h2>
				<p>&nbsp;<strong>TITOLARE DEL TRATTAMENTO DEI DATI</strong></p>
				<p>Iterego. Via P. Emiliani Giudici, 25 - Caltanissetta 93100</p>
				<p>Indirizzo email del Responsabile della Privacy (RDP): info@iterego.it</p>

				<hr></hr>
				
				<p><strong>TIPOLOGIE DI DATI RACCOLTI</strong></p>
				<p>Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: nome, cognome, data di nascita, numero di telefono, indirizzo, CAP, città, Codice Fiscale, Cookie, Dati di utilizzo e email.</p>
				<p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.</p>
				<p>I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.</p>
				<p>Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.</p>
				<p>Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.</p>
				<p>L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.</p>
				<p>L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.</p>
				
				<hr></hr>
				
				<p><strong>MODALITÀ E LUOGO DEL TRATTAMENTO DEI DATI RACCOLTI</strong></p>
				<p><strong>Modalità di trattamento</strong></p>
				<p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.</p>
				<p>Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>
				<p><strong>Base giuridica del trattamento</strong></p>
				<p>Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:</p>
				<ul>
				<li>l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;</li>
				<li>il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;</li>
				<li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</li>
				<li>il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;</li>
				<li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</li>
				</ul>
				<p>E’ comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>
				<p><strong>Luogo</strong></p>
				<p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.</p>
				<p>I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
				<p>L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.</p>
				<p>Qualora abbia luogo uno dei trasferimenti appena descritti, l’Utente può fare riferimento alle rispettive sezioni di questo documento o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.</p>
				<p><strong>Periodo di conservazione</strong></p>
				<p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.</p>
				<p>Pertanto:</p>
				<ul>
				<li>I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</li>
				<li>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</li>
				</ul>
				<p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.</p>
				<p>Al termine del periodo di conservazioni i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.</p>
				<p><strong>Finalità del Trattamento dei Dati raccolti</strong></p>
				<p>I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, così come per le seguenti finalità: Contattare l'Utente, Statistica e Interazione con social network e piattaforme esterne.</p>
				<p>Per ottenere ulteriori informazioni dettagliate sulle finalità del trattamento e sui Dati Personali concretamente rilevanti per ciascuna finalità, l’Utente può fare riferimento alle relative sezioni di questo documento.</p>
				<p><strong>Dettagli sul trattamento dei Dati Personali</strong></p>
				<p>I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>
				<ul>
				<li>Contattare l'Utente</li>
				<li>Interazione con social network e piattaforme esterne</li>
				<li>Statistica</li>
				</ul>
				<p><strong>Diritti dell’Utente</strong></p>
				<p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
				<p>In particolare, l’Utente ha il diritto di:</p>
				<ul>
				<li>revocare il consenso in ogni momento. L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.</li>
				<li>opporsi al trattamento dei propri Dati. L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.</li>
				<li>accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.</li>
				<li>verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.  </li>
				<li>ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.</li>
				<li>ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare. </li>
				<li>ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.</li>
				<li>proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
				</ul>
				<p><strong>Dettagli sul diritto di opposizione</strong></p>
				<p>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
				<p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>
				<p><strong>Come esercitare i diritti</strong></p>
				<p>Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.</p>
				
				<hr></hr>

				<p><strong>ULTERIORI INFORMAZIONI SUL TRATTAMENTO</strong></p>
				<p><strong>Difesa in giudizio</strong></p>
				<p>I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o dei Servizi connessi da parte dell’Utente.</p>
				<p>L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.</p>
				<p>Informative specifiche</p>
				<p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>
				<p><strong>Log di sistema e manutenzione</strong></p>
				<p>Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.</p>
				<p><strong>Informazioni non contenute in questa policy</strong></p>
				<p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>
				<p>Risposta alle richieste “Do Not Track”</p>
				<p><strong>Questa Applicazione non supporta le richieste “Do Not Track”.</strong></p>
				<p>Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive privacy policy.</p>
				<p><strong>Modifiche a questa privacy policy</strong></p>
				<p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso il Titolare . Si prega dunque di consultare regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>
				<p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>
				
				<hr></hr>

				<p><strong>DEFINIZIONI E RIFERIMENTI LEGALI</strong></p>
				<p><strong>Dati Personali (o Dati)</strong></p>
				<p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
				<p><strong>Dati di Utilizzo</strong></p>
				<p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>
				<p><strong>Utente</strong></p>
				<p>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.</p>
				<p>Interessato</p>
				<p>La persona fisica cui si riferiscono i Dati Personali.</p>
				<p>Responsabile del Trattamento (o Responsabile)</p>
				<p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
				<p>Titolare del Trattamento (o Titolare)</p>
				<p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.</p>
				<p><strong>Questa Applicazione</strong></p>
				<p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
				<p><strong>Servizio</strong></p>
				<p>Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
				<p><strong>Unione Europea (o UE)</strong></p>
				<p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.</p>
				<p><strong>Cookie</strong></p>
				<p>Piccola porzione di dati conservata all'interno del dispositivo dell'Utente.</p>
				
				<hr></hr>

				<p><strong>RIFERIMENTI LEGALI</strong></p>
				<p>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
				<p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
				<p>&nbsp;</p>
		 	</Col>

		 	<Col>

		 		<h2> Cookie policy </h2>

		 		<p>Il presente sito web utilizza cookie tecnici per garantire il corretto funzionamento delle procedure e migliorare l'esperienza di uso delle applicazioni online. Il presente documento fornisce informazioni sull'uso dei cookie e di tecnologie similari, su come sono utilizzati dal sito e su come gestirli.</p>
				<p><strong>Definizioni</strong></p>
				<p>I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, ove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla visita successiva. I cookie delle c.d. "terze parti" vengono, invece, impostati da un sito web diverso da quello che l'utente sta visitando. Questo perché su ogni sito possono essere presenti elementi (immagini, mappe, suoni, specifici link a pagine web di altri domini, ecc.) che risiedono su server diversi da quello del sito visitato.</p>
				<p><strong>Tipologie di cookie</strong></p>
				<p>In base alle caratteristiche e all'utilizzo dei cookie si possono distinguere diverse categorie:</p>
				<p>- Cookie tecnici. I cookie tecnici sono quelli utilizzati al solo fine di "effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio" (cfr. art. 122, comma 1, del Codice).</p>
				<p>Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web; cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati al fine di migliorare il servizio reso allo stesso. Per l'installazione di tali cookie non è richiesto il preventivo consenso degli utenti, mentre resta fermo l'obbligo di dare l'informativa ai sensi dell'art. 13 del Codice, che il gestore del sito, qualora utilizzi soltanto tali dispositivi, potrà fornire con le modalità che ritiene più idonee.</p>
				<p>- Cookie di profilazione. I cookie di profilazione sono volti a creare profili relativi all'utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell'ambito della navigazione in rete. In ragione della particolare invasività che tali dispositivi possono avere nell'ambito della sfera privata degli utenti, la normativa europea e italiana prevede che l'utente debba essere adeguatamente informato sull'uso degli stessi ed esprimere così il proprio valido consenso. Ad essi si riferisce l'art. 122 del Codice laddove prevede che "l'archiviazione delle informazioni nell'apparecchio terminale di un contraente o di un utente o l'accesso a informazioni già archiviate sono consentiti unicamente a condizione che il contraente o l'utente abbia espresso il proprio consenso dopo essere stato informato con le modalità semplificate di cui all'articolo 13, comma 3" (art. 122, comma 1, del Codice). Il presente sito non utilizza cookie di profilazione.</p>
				<p><strong>Cookie di "terze parti"</strong></p>
				<p>Visitando il presente sito web si potrebbero ricevere cookie da siti gestiti da altre organizzazioni ("terze parti"). Un esempio è rappresentato dalla presenza dei "social plugin" per Facebook, Twitter, Google+ o LinkedIn, oppure sistemi di visualizzazione di contenuti multimediali embedded (integrati) come ad esempio Youtube, Flikr. Si tratta di parti generate direttamente dai suddetti siti ed integrati nella pagina web del sito ospitante visitato. La presenza di questi plugin comporta la trasmissione di cookie da e verso tutti i siti gestiti da terze parti. La gestione delle informazioni raccolte da "terze parti" è disciplinata dalle relative informative cui si prega di fare riferimento. Per garantire una maggiore trasparenza e comodità, si riportano qui di seguito gli indirizzi web delle diverse informative e delle modalità per la gestione dei cookie.</p>
				<p><a href="https://www.facebook.com/help/cookies/">Facebook informativa</a>&nbsp;<br /> <a href="https://support.twitter.com/articles/20170514">Twitter informativa</a>&nbsp;<br /> <a href="https://twitter.com/settings/security">Twitter (configurazione)</a>&nbsp;<br /> <a href="https://www.linkedin.com/legal/cookie-policy">Linkedin informativa</a>&nbsp;<br /> <a href="https://www.linkedin.com/settings/">Linkedin (configurazione)</a>&nbsp;<br /> <a href="http://www.google.it/intl/it/policies/technologies/cookies/">Youtube\Google+ informativa:&nbsp;</a>&nbsp;<br /> <a href="http://www.google.it/intl/it/policies/technologies/managing/">Youtube\Google+ (configurazione):&nbsp;</a>&nbsp;</p>
				<p><strong>Cookie analytics</strong></p>
				<p><strong>WebTrends</strong></p>
				<p>Al solo fine di monitorare e migliorare le prestazioni del sito ci si avvale di un prodotto di mercato di analisi statistica per la rilevazione degli accessi al sito. Esso può ricorrere all'utilizzo di cookies, permanenti e non, allo scopo di raccogliere informazioni statistiche e sui "visitatori unici" del sito. I cookies, definiti come "Unique Visitor Cookies", contengono un codice alfanumerico che identifica i computer di navigazione, senza tuttavia alcuna raccolta di dati personali.</p>
				<p><strong>Google Analytics</strong></p>
				<p>Il sito include anche componenti trasmesse da Google Analytics, un servizio di analisi del traffico web fornito da Google, Inc. ("Google"). Tali cookie sono usati al solo fine di monitorare e migliorare le prestazioni del sito. Per ulteriori informazioni, si rinvia al <a href="https://www.google.it/policies/privacy/partners/">seguente link</a></p>
				<p>L'utente può disabilitare in modo selettivo l'azione di Google Analytics installando sul proprio browser la componente di opt-out fornito da Google. Per disabilitare l'azione di Google Analytics, si rinvia al <a href="https://tools.google.com/dlpage/gaoptout">seguente link</a></p>
				<p><strong>Durata dei cookie</strong></p>
				<p>Alcuni cookie (cookie di sessione) restano attivi solo fino alla chiusura del browser o all'esecuzione dell'eventuale comando di logout. Altri cookie "sopravvivono" alla chiusura del browser e sono disponibili anche in successive visite dell'utente. Questi cookie sono detti persistenti e la loro durata è fissata dal server al momento della loro creazione. In alcuni casi è fissata una scadenza, in altri casi la durata è illimitata.</p>
				<p><strong>Gestione dei cookie</strong></p>
				<p>L'utente può decidere se accettare o meno i cookie utilizzando le impostazioni del proprio browser.&nbsp;<br /> Attenzione: con la disabilitazione totale o parziale dei cookie tecnici potrebbe compromettere l'utilizzo ottimale del sito.&nbsp;<br /> La disabilitazione dei cookie "terze parti" non pregiudica in alcun modo la navigabilità.<br /> L'impostazione può essere definita in modo specifico per i diversi siti e applicazioni web. Inoltre i browser consentono di definire impostazioni diverse per i cookie "proprietari" e per quelli di "terze parti". A titolo di esempio, in Firefox, attraverso il menu Strumenti-&gt;Opzioni-&gt;Privacy, è possibile accedere ad un pannello di controllo dove è possibile definire se accettare o meno i diversi tipi di cookie e procedere alla loro rimozione. In internet è facilmente reperibile la documentazione su come impostare le regole di gestione dei cookies per il proprio browser, a titolo di esempio si riportano alcuni indirizzi relativi ai principali browser:&nbsp;<br /> <br /> 

				<a href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a>&nbsp;<br /> 
				<a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Firefox</a>&nbsp;<br /> 
				<a href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9">Internet Explorer</a>&nbsp;<br /> 
				<a href="http://help.opera.com/Windows/10.00/it/cookies.html">Opera</a>&nbsp;<br /> 
				<a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">Safari</a></p>

		 	</Col>
		</Row>
	</Container>
);

export default privacy;