import React, {Component} from 'react';

import {Container, Row, Col, Accordion, Card} from 'react-bootstrap';
import {Radar} from 'react-chartjs-2';

import facebook from '../../assets/images/facebook.png';
import whatsapp from '../../assets/images/whatsapp.png';
import linkedin from '../../assets/images/linkedin.png';
import loading from '../../assets/images/loading.gif';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton
} from 'react-share';

//Import query string library
import qs from 'qs';

//API lib
import api from "../../api";

import './Results.css';

class Results extends Component {

	constructor(props){
		super(props);

		this.initialState = {
			results: null,
			resultsLoading: true
		};

		this.state = this.initialState; //Store the initial state, so it can be restored
	}

	componentDidMount(){

		//Check if user exist:
		if (this.props.appstate.user) {

			//Check for URL param
			//Esempio: risultati?quiz_id=1
			const url_params = this.props.location.search;

			if (url_params) {
				const p = url_params.split("=")[0]; //param
				if (p === "?quiz_id") {

					const quiz_id = url_params.split("=")[1]; //quiz_id value

					//Request results //token: props.appstate.token
					api.post('answers/results?token=' + this.props.appstate.token, qs.stringify({
				        "employee_id": this.props.appstate.user.employee_id,
				        "quiz_id": quiz_id
				    }))
					.then(res => {
					   	if(res.status === 200){
							this.setState({
								results: res.data.data,
								resultsLoading: false
							});
					   	} else {
					   		alert("Errore Caricamento Risultati. Riprova o contatta il supporto.")
					   	}
					});
				}

			} else {
				//No Params
				//Move to Home Page
				console.log("No URL params found");
				this.props.history.push('/');
			}
		} else {
			//No User
			//Move to login
			console.log("No User found");
			this.props.history.push('/');
		}
	}

	render(){

		//Create the results dom obj
		let radar = <img id="loadingIcon" src={loading} alt="Loading Icon"/>; //the chart object
		let cards = <img id="loadingIcon" src={loading} alt="Loading Icon"/>; //cards object
		let tip = <img id="loadingIcon" src={loading} alt="Loading Icon"/>; //tip, suggestion

		if (!this.state.resultsLoading) {

			const chartdata = {
				labels: this.state.results.map(area => {
					return (area.alias)
			  	}),

			  	datasets: [
					{
					  label: 'Quiz',
					  backgroundColor: '#8cc44d87',
					  borderColor: '#4f8964',

					  pointBackgroundColor: '#4f8964',
					  pointBorderColor: '#4f8964',

					  pointHoverBackgroundColor: '#fff',
					  pointHoverBorderColor: 'rgba(179,181,198,1)',
					  
					  pointRadius:5,

					  //data: [10,10,10,6,6]
					  data: this.state.results.map(area => {
						const sum = area.answers.reduce((a, b) => a + b, 0);
						const max = area.answers.length * 3;
						const min = area.answers.length;
						return ((sum - min) / (max - min))*100; //percentual
					  })
					}
			  	]
			};

			const chartoptions = {
				responsive: true,
				legend: {
					display: false
				},
				scale: {
					pointLabels :{
					   fontStyle: "bold",
					},
					ticks: {
						beginAtZero: true,
						max: 100,
						min: 0,
						stepSize: 25,
						display: false
					}
				}
			};

			//Create the Radar Chart
			radar = <Radar data={chartdata} options={chartoptions} height={100} width={100}/>;
			
			//Create the result cards
			cards = this.state.results.map((area, index) => {

				//Calculate the percentage:
				const sum = area.answers.reduce((a, b) => a + b, 0);
				const max = area.answers.length * 3;
				const min = area.answers.length;
				const percentual = ((sum - min) / (max - min))*100;

				let resultLabel = null; //ALTA, MEDIA, BASSA
				let resultBody = null;
				if (percentual > 66) {
					resultLabel = "ALTA";
					resultBody = area.feedbacks[0].high;
				} else if (percentual > 33) {
					resultLabel = "MEDIA";
					resultBody = area.feedbacks[0].medium;
				} else {
					resultLabel = "BASSA";
					resultBody = area.feedbacks[0].low;
				}


				return (
					<Card key={area.id}>
						<Card.Header>
							<Accordion.Toggle as={"button"} variant="link" eventKey={index}>
								{area.alias}: <span>{resultLabel}</span>
							</Accordion.Toggle>
						</Card.Header>
						<Accordion.Collapse eventKey={index}>
						<Card.Body dangerouslySetInnerHTML={{__html: resultBody}}>
						</Card.Body>
						</Accordion.Collapse>
					</Card>
				)
			});

			//Tip:
			const randomInt = Math.floor(Math.random() * this.state.results.length);
			tip = this.state.results[randomInt].feedbacks[0].tip;
		}

		return (
			<Container className="Results">

				<Row>
					<Col xs="12" md="6">
						<h3> Ecco i tuoi risultati </h3>
						{radar}
					</Col>

					<Col xs="12" md="6" className="profile">
						<h3> Il tuo profilo </h3>
						<p> Clicca sul nome dell'area per approfondire l'argomento </p>
						
						<Accordion>
							{cards}
						</Accordion>
					</Col>

					<Col xs="12" md="12" className="tip">
						<h3> Un consiglio per te </h3>
						<div dangerouslySetInnerHTML={{__html: tip}} />
						<p> <a href="mailto:info@iterego.it?subject=Richiesta%20Informazioni%20AppForUp">Contattaci</a> e scopri come possiamo aiutarti. </p>
					</Col>

					<Col xs="12" className="shareButtons">

						<h5>Fai conoscere AppForUp ai tuoi amici e colleghi</h5>

				   		<FacebookShareButton url="www.appforup.it">
				   			<img src={facebook} alt="Facebook share icon" />
				   		</FacebookShareButton>

				   		<WhatsappShareButton url="www.appforup.it">
				   			<img src={whatsapp} alt="Whatsapp share icon" />
				   		</WhatsappShareButton>

				   		<LinkedinShareButton url="www.appforup.it">
				   			<img src={linkedin} alt="Linkedin share icon" />
				   		</LinkedinShareButton>
				   	</Col>
				</Row>
			</Container>
		);
	}
	
}

export default Results;