import React, {Component} from 'react';
import {Container, Row, Col, Button, Card, Accordion} from 'react-bootstrap';
import './CompanyPanel.css';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';

import {Radar} from 'react-chartjs-2';

//Import query string library
import qs from 'qs';

//API lib
import api from "../../api";

class CompanyPanel extends Component {

	constructor(props){
		super(props);

		this.initialState = {
			quizzes: null, //Array of all available quizzes
			quizSelected: null, //id of the selected quiz
			quizLink: null,

			results: null //Aggregate results from employee
		}
		this.state = this.initialState; //Store the initial state, so it can be restored
	}

	componentDidMount(){

		//Load all available quizzes
		//Get the quiz from API
		api.get('/quizzes/get', {})
	    .then(res => {
	    	this.setState({
			    quizzes: res.data.data
			});
		});

		this.getQuizResults();
		
	}

	getQuizResults = () => {
		
		//Check if quizSelected and quizLink are saved in browser
    	const quizSelected = localStorage.getItem('quizSelected');
    	const quizLink = localStorage.getItem('quizLink');

    	if ((quizSelected)&&(quizLink)) {

    		//Get quizLink from API
			const post_data = {
				"quiz_id": quizSelected
			}
			api.post('companies/results?token=' + this.props.appstate.token, qs.stringify(post_data))
			.then(res => {
				this.setState({
					results: res.data.data,
	    			quizSelected: quizSelected,
	    			quizLink: quizLink
	    		});
			});	
    	}
	}

	handleClickSignout = () => {
		this.props.signOut(success => {
	    	if (success) {
	    		//redirect
	    		this.props.history.replace('entra');
	    	}
	    });
	}

	handleClickGetQuizlink(quiz_id) {

    	//Get quizLink from API
		const post_data = {
			"company_id": this.props.appstate.companies.id,
			"quiz_id": quiz_id
		}

		api.post('companies/quizlink?token=' + this.props.appstate.token, qs.stringify(post_data))
		.then(res => {

			this.setState({
				quizSelected: quiz_id,
				quizLink: res.data.data.link
			});

			//Save choose in local Storage
    		localStorage.setItem('quizSelected', quiz_id);
			localStorage.setItem('quizLink', res.data.data.link);

			this.getQuizResults();
		});
	}

	handleClickCopy = () => {
		const urlToCopy = window.location.href.replace("pannelloazienda", "quiz") + "?quizlink=" + this.state.quizLink;
		navigator.clipboard.writeText(urlToCopy);
		alert("Link Copiato! Condividilo tra i tuoi dipendenti. I risultati dei quiz appariranno su questa pagina.");
	}

	render(){

		//Display a set of buttons
		let quizlink_button = null;
		if(this.state.quizzes){
			quizlink_button = this.state.quizzes.map(quiz => {
				return(
					<button key={quiz.id} onClick={() => this.handleClickGetQuizlink(quiz.id)}>{quiz.name}</button>
				);
			});
		}

		//Display results - count
		let count = null;
		if (this.state.results) {
			count = <p> Quiz Completati: <span id="countEmployees">{this.state.results.countEmployees}</span> </p>
		}

		//Display results
		let radar = null;
		let cards = null;
		let tip = null;

		if (this.state.results) {

			if (this.state.results.countEmployees > 0) {
				//Wait dario chart
				const chartdata = {
					labels: this.state.results.areas.map(area => {
						return (area.alias)
				  	}),

				  	datasets: [
						{
						  label: this.state.results.quiz.name,
						  backgroundColor: '#8cc44d87',
						  borderColor: '#4f8964',

						  pointBackgroundColor: '#4f8964',
						  pointBorderColor: '#4f8964',

						  pointHoverBackgroundColor: '#fff',
						  pointHoverBorderColor: 'rgba(179,181,198,1)',
						  
						  pointRadius:5,

						  //data: [10,10,10,6,6]
						  data: this.state.results.areas.map(area => {
							return(area.percentuale) //TOFIX
						  })
						}
				  	]
				};

				const chartoptions = {
					responsive: true,
					legend: {
						display: false
					},
					scale: {
						pointLabels :{
						   fontStyle: "bold",
						},
						ticks: {
							beginAtZero: true,
							max: 100,
							min: 0,
							stepSize: 25,
							display: false
						}
					}
				};

				//Create the Radar Chart
				radar = <Radar data={chartdata} options={chartoptions} height={100} width={100}/>;

				//Create the result cards
				cards = this.state.results.areas.map((area, index) => {

					let resultLabel = null; //ALTA, MEDIA, BASSA
					let resultBody = null;
					if (area.percentuale > 66) { //TOFIX
						resultLabel = "ALTA";
						resultBody = area.feedbacks[0].high;
					} else if (area.percentuale > 33) {//TOFIX
						resultLabel = "MEDIA";
						resultBody = area.feedbacks[0].medium;
					} else {
						resultLabel = "BASSA";
						resultBody = area.feedbacks[0].low;
					}


					return (
						<Card key={index}>
							<Card.Header>
								<Accordion.Toggle as={"button"} variant="link" eventKey={index}>
									{area.alias}: <span>{resultLabel}</span>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey={index}>
							<Card.Body dangerouslySetInnerHTML={{__html: resultBody}}>
							</Card.Body>
							</Accordion.Collapse>
						</Card>
					)
				});

				//Tip:
				const randomInt = Math.floor(Math.random() * this.state.results.areas.length);
				tip = this.state.results.areas[randomInt].feedbacks[0].tip;
			}
		}

		//Display Loading at start
		if (this.props.appstate.user) {
			return(
			   	<Container className="CompanyPanel">
					<Row>

						<Col xs="12" className="companyInfo">
					 		<h3>Pannello Azienda</h3>

					 		<p><b>Azienda</b>: {this.props.appstate.companies.ragione_sociale}</p>

						 	<p><b>Nome</b>: {this.props.appstate.user.nome}</p>
						 	<p><b>Cognome</b>: {this.props.appstate.user.cognome}</p>
						 	<p><b>Email</b>: {this.props.appstate.user.email}</p>
						 	<Button variant="warning" onClick={this.handleClickSignout}> Esci </Button>
						 	
						</Col>

						<Col xs="12" id="linkContainer">
					 		<h3>Il tuo Link aziendale</h3>

					 		{this.state.quizSelected? 
					 		<Auxiliary>
					 			<p> Condividi questo link fra i tuoi dipendenti: </p>
						 		<input type="text" name="country" value={window.location.href.replace("pannelloazienda", "quiz") + "?quizlink=" + this.state.quizLink} readOnly></input>
						 		<button onClick={this.handleClickCopy}> Copia Link </button>
					 		</Auxiliary>
					 		: 
					 		<Auxiliary>
					 			<p>Clicca su uno di questi quiz per ottenere un link da far girare tra i tuoi dipendenti.</p>
					 			{quizlink_button}
					 		</Auxiliary>
					 		}
						</Col>

						{
					 		this.state.results?
					 		<Auxiliary>
								<Col xs="12" md="6">
									<h3> I risultati del quiz </h3>
							 		{count}
							 		{radar}
								</Col>
								
							</Auxiliary>
						: null}

						{cards? 
							<Col xs="12" md="6" className="profile">
								<h3> Il profilo aziendale </h3>
								<p> Clicca sul nome dell'area per approfondire l'argomento </p>
							 	<Accordion>
									{cards}
								</Accordion>
							</Col>
						: null}

						{tip? 
							<Col xs="12" md="12" className="tip">
								<h3> Un consiglio per te </h3>
								<div dangerouslySetInnerHTML={{__html: tip}} />
								<p> <a href="mailto:info@iterego.it?subject=Richiesta%20Informazioni%20AppForUp">Contattaci</a> e scopri come possiamo aiutarti. </p>
							</Col>
						: null}

					</Row>
				</Container>
			);
		} else {
			return (null)
		}
	}
}

export default CompanyPanel;