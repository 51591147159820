import React, {Component} from 'react';
import { Route, Switch } from 'react-router-dom';

//Import query string library
import qs from 'qs';

//Sub Components
import Topbar from './components/Navigation/Topbar/Topbar';
import MobileMenu from './components/Navigation/MobileMenu/MobileMenu';
import Footer from './components/Footer/Footer';

import Home from './components/Home/Home';
import QuizController from './components/QuizController/QuizController';
import Results from './components/Results/Results';
import Signin from './components/Signin/Signin';
import Signup from './components/Signup/Signup';
import Company from './components/Company/Company';
import About from './components/About/About';
import Privacy from './components/Privacy/Privacy';

import EmployeePanel from './components/EmployeePanel/EmployeePanel';
import CompanyPanel from './components/CompanyPanel/CompanyPanel';

//Api
import api from "./api";

//Style
import './App.css';

/*
  App
  This component is the root render component and manage the routing.

  User roles:
  1. Super Admin (root)
  2. Administrator (admin)
  3. Dipendente (employee)
  4. Azienda (azienda)
  5. Agenzia (agenzia)

  Test User credential:
    
  http://localhost:3000/quiz?quizlink=5de8df5b27708

  Azienda
  836615e408@emailcu.icu

  nome: Luigi Scalfaro
  azienda: Scalfaro Scarpe

  mario nicosia 8810e3acfe@emailcu.icu

*/

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      showMobileMenu: false,
      isLoggedIn: false,
      user: null,
      token: null,
      companies: null //Only for Company (information about Company)
    }
  }

  componentDidMount() {

    console.log("Mounting app.");

    //Check if token is saved in browser
    const token = localStorage.getItem('token');
    if (token){
      
      //Token already saved
      console.log("Token installed. Acquiring user data.");

      //Get user info from backend
      //Get the quiz from API
      api.get('/users/get', {
        params: {token: token}
      })
      .then(res => {

        const role_id = res.data.data.user.role_id;

        if (role_id === 3) {
          this.setState({
            token: token,
            isLoggedIn: true,
            user: {
              nome: res.data.data.user.nome,
              cognome: res.data.data.user.cognome,
              email: res.data.data.user.email,
              id: res.data.data.user.id,

              role: role_id,
              employee_id: res.data.data.user.employees[0].id
            },
          });
        }
        //Company
        else if(role_id === 4){

          //Get info and save in state
          this.setState({
            token: token,
            isLoggedIn: true,
            user: {
              nome: res.data.data.user.nome,
              cognome: res.data.data.user.cognome,
              email: res.data.data.user.email,
              id: res.data.data.user.id,

              role: role_id
            },
            companies: res.data.data.user.companies[0]
          });
        }
      }).catch(error =>{
        //Token non valido. Resettare.
        console.log("Token Error");
        this.setState({
          token: null,
          isLoggedIn: false,
          user: null,
        });
        localStorage.clear();
      });

    } else {
      //Token not found
      console.log("Token not found");
    }

  }

  /* 
    mobileMenuClosedHandler
    ----------------------------------
    Handle click on close menu
  */
  mobileMenuClosedHandler = () => {
    this.setState({ 
      showMobileMenu: false 
    });
  }

  /* 
    mobileMenuToggleHandler
    ----------------------------------
    Handle click on toggle menu (burger button)
  */
  mobileMenuToggleHandler = () => {
    this.setState( ( prevState ) => {
      return { showMobileMenu: !prevState.showMobileMenu };
    });
  }



  //--------------------------------------------------------------------
  //--------------------------------------------------------------- AUTH
  //--------------------------------------------------------------------

  /* 
    signIn
    ----------------------------------
    Handle the signin process
    Params:
      - email
      - password
      - callback (return true or false)
  */
  signIn = (email, password, callback) => {

    //Using query string library due to this error:
    //https://stackoverflow.com/questions/29954037/why-is-an-options-request-sent-and-can-i-disable-it
    //See also: https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
    api.post('users/login/', qs.stringify({
      email: email,
      password: password
    }))
    .then(res => {

      //console.log(res);
      
      if (res.status === 200) {
        
        console.log("Login effettuato.");
        
        const role_id = res.data.data.user.role_id;

        //Employee
        if (role_id === 3) {
          //Get info and save in state
          this.setState({
            user: {
              nome: res.data.data.user.nome,
              cognome: res.data.data.user.cognome,
              email: res.data.data.user.email,
              id: res.data.data.user.id,

              role: role_id,
              employee_id: res.data.data.user.employees[0].id
            },
            isLoggedIn: true,
            token:res.data.data.token
          });
        } 
        //Company
        else if(role_id === 4){
          //Get info and save in state
          this.setState({
            user: {
              nome: res.data.data.user.nome,
              cognome: res.data.data.user.cognome,
              email: res.data.data.user.email,
              id: res.data.data.user.id,

              role: role_id
            },
            companies: res.data.data.user.companies[0],
            isLoggedIn: true,
            token:res.data.data.token
          });
        }

        //Save token in browser
        localStorage.setItem("token", res.data.data.token);

        callback(true);
      }
    }).catch(error => {
      
      callback(false);

    });
  }

  /* 
    signOut
    ----------------------------------
    Handle the signout process
    Params:
      - callback (return true or false)
  */
  signOut = (callback) => {

    console.log("Loggin out");

    localStorage.clear();

    //Fake SignOut
    this.setState({
      isLoggedIn: false,
      user: null,
      token: null
    });
    
    callback(true);
  }

  /* 
    signUp
    ----------------------------------
    Handle the signup process
    Params:
      - email
      - password
      - callback (return true or false)
  */
  signUp = (nome, cognome, email, password, ragione_sociale, partita_iva, numero_dipendenti, callback) => {
    
    //Using query string library due to this error:
    //https://stackoverflow.com/questions/29954037/why-is-an-options-request-sent-and-can-i-disable-it
    //See also: https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
    api.post('companies/register/', qs.stringify({
      user: {
        nome: nome,
        cognome: cognome,
        email: email,
        email_repeat: email,
        password: password
      },
      company:{
        ragione_sociale: ragione_sociale,
        partita_iva: partita_iva,
        numero_dipendenti: numero_dipendenti
      }
    }))
    .then(res => {
      if (res.status === 200) {
        console.log("Registrazione Azienda Effettuata.");
        //console.log(res);
        callback(true);
      }
    }).catch(error => {
      
      callback(false);

    });
  }


  /* 
    verifyPin
    ----------------------------------
    Handle the verification process
    Params:
      - callback (return true or false)
  */
  verifyPin = (pin, callback) => {

    console.log("App.js - verfiy pin");

    api.post('users/verify', qs.stringify({
      pin: pin
    }))
    .then(res => {
        if((res.status === 200)&&(!res.data.error)){
          
          //Get token from response:
          const token = res.data.data.token;

          //Get user data
          api.get('/users/get', {
            params: {token: token}
          })
          .then(res => {

            //console.log(res);

            //Verifica Employee
            if (res.data.data.user.role_id === 3) {
              this.setState({
                token: token,
                isLoggedIn: true,
                user: {
                  nome: res.data.data.user.nome,
                  cognome: res.data.data.user.cognome,
                  email: res.data.data.user.email,
                  id: res.data.data.user.id,
                  role: res.data.data.user.role_id,

                  employee_id: res.data.data.user.employees[0].id
                },
              });
            } 
            //Verifica Company
            else if (res.data.data.user.role_id === 4) {
              this.setState({
                token: token,
                isLoggedIn: true,
                user: {
                  nome: res.data.data.user.nome,
                  cognome: res.data.data.user.cognome,
                  email: res.data.data.user.email,
                  id: res.data.data.user.id,
                  role: res.data.data.user.role_id
                },
                companies: res.data.data.user.companies[0]
              });
            }
            

            //Save token in browser
            localStorage.setItem("token", token);
            callback(true);
          });
        } else {
          callback(false);
        }
    }).catch(error => {
      callback(false);
    });
  }


  render(){
    return (
      <div className="App">

        <Topbar 
          appstate={this.state}
          open={this.state.showMobileMenu}
          mobileMenuToggleClicked={this.mobileMenuToggleHandler} />

        <MobileMenu
          appstate={this.state}
          open={this.state.showMobileMenu}
          closed={this.mobileMenuClosedHandler} />
        
        <main>
          <Switch>
              <Route path="/" exact render={(props) => <Home {...props} appstate={this.state}/>} />
              <Route path="/quiz" exact render={(props) => <QuizController {...props} appstate={this.state} verifyPin={this.verifyPin}/>} />
              <Route path="/risultati" exact render={(props) => <Results {...props} appstate={this.state}/>} />
              
              <Route path="/entra" exact render={(props) => <Signin {...props} appstate={this.state} signIn={this.signIn}/>} />
              <Route path="/registrati" exact render={(props) => <Signup {...props} appstate={this.state} signUp={this.signUp} verifyPin={this.verifyPin}/>} />

              <Route path="/pannellodipendente" exact render={(props) => <EmployeePanel {...props} appstate={this.state} signOut={this.signOut} />} />
              <Route path="/pannelloazienda" exact render={(props) => <CompanyPanel {...props} appstate={this.state} signOut={this.signOut}/>} />

              <Route path="/perleaziende" exact render={(props) => <Company {...props} appstate={this.state}/>} />
              <Route path="/chisiamo" exact render={(props) => <About {...props} appstate={this.state}/>} />
              <Route path="/privacy" exact render={(props) => <Privacy {...props} appstate={this.state}/>} />
            </Switch>
        </main>

        <Footer />
        
      </div>
    );
  }
}

export default App;
