import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
//Images
import loading from '../../../assets/images/loading.gif';

import './Choose.css';

const choose = ( props ) => {
	
	let quizzes = <img id="loadingIcon" src={loading} alt="Loading Icon"/>;

	if (props.type === "unknown") {
		if (props.quizzes) {
			quizzes = props.quizzes.map((quiz, index) => {
				return (
					<Card key={quiz.id}>
					  <Card.Body>
					    <Card.Title>{quiz.name}</Card.Title>
					    <Card.Text>
					      Il quiz per testare le tue competenze lavorative
					    </Card.Text>
					    <button className="ds-button" variant="primary" onClick={() => props.selectQuiz(index)}>Inizia</button>
					  </Card.Body>
					</Card>
				);
			});
		}
	} else if (props.type === "known") {

		if (props.quizzes) {

			quizzes = props.quizzes.map((quiz, index) => {

				let button = <button className="ds-button" variant="primary" onClick={() => props.selectQuiz(index)}>Inizia</button>;

				//Check if quiz is done
				if (props.quizzesCompleted) {
					for (var i = 0; i < props.quizzesCompleted.length; i++) {
						if (props.quizzesCompleted[i].quiz_id === quiz.id) {
							button = <button className="ds-button" variant="primary" onClick={null} disabled>Quiz già fatto</button>;
						}
					}
				}
				
				return (
					<Card style={{ width: '18rem' }} key={quiz.id}>
					  <Card.Body>
					    <Card.Title>{quiz.name}</Card.Title>
					    <Card.Text>
					      Il quiz per testare le tue competenze lavorative
					    </Card.Text>
					    {button}
					  </Card.Body>
					</Card>
				);
			});
		}
	}
	
	return (
		<Container className="Choose">
			<Row>
				<Col>
					<h3> Scegli un Quiz </h3>
					{quizzes}
				</Col>
			</Row>
		</Container>
	);

}

export default choose;