import React  from 'react';
import {Container, Row, Col, ButtonGroup, ProgressBar, Modal, Alert} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import lampadina from '../../../assets/images/lampadina.png';

import './Quiz.css';

const quiz = ( props ) => {

	//Calculate current area, question, modal and multimedia
	let area = null, question_text = null, question_id = null, modal = null, multimedia = null, label = null;

	if (props.quiz) {

		//Area Calculation
		area = (
			<h3 className="area">
				<span>{props.areaindex+1}</span>
				{props.quiz.areas[props.areaindex].name}
			</h3>
		);

		//Question Calculation
		question_text = props.quiz.areas[props.areaindex].questions[props.questionindex].testo_domanda;	
		question_id = props.quiz.areas[props.areaindex].questions[props.questionindex].id;	
		
		//Modal Calculation
		modal = props.quiz.areas[props.areaindex].questions[props.questionindex].modal_info;

		//Multimedia Calculation
		let multimedia_type = props.quiz.areas[props.areaindex].questions[props.questionindex].multimedia_type;
		let multimedia_link = props.quiz.areas[props.areaindex].questions[props.questionindex].multimedia_link;
		if (multimedia_type) {
			if (multimedia_type === "image") {
				multimedia = <img src={multimedia_link} alt="Domanda Multimedia"/>
			}		
		}

		//Label Calculation
		label = props.quiz.areas[props.areaindex].questions[props.questionindex].label;
	}

	//Calculate button state, depending on results
	let buttonsStatus = ["light", "light", "light"];
	if (props.answers[props.areaindex][props.questionindex]) {
		buttonsStatus = [
			(props.answers[props.areaindex][props.questionindex].answer === 1) ? "selected" : "",
			(props.answers[props.areaindex][props.questionindex].answer === 2) ? "selected" : "",
			(props.answers[props.areaindex][props.questionindex].answer === 3) ? "selected" : ""
		];
	}

	//Calculate progress bar state
	//Calculation of the total number of questions:
	let totansweredquestions = 0;
	if (props.answers) {
		for (var i = 0; i < props.answers.length; i++) {
			for (var j = 0; j < props.answers[i].length; j++) {
				if(props.answers[i][j] != null){
					totansweredquestions += 1;
				}
			}
		}
	}

	const progress = Math.floor((totansweredquestions / props.totquestions)*100);

	//Calculate if display alert:
	let alert = null;
	if (props.displayAlert) {
		alert = (
			<Alert variant="danger">
				Rispondi alla domanda per continuare.
			</Alert>
		);
	}

	return (
		<Container className="Quiz">
			<Row>
				<Col>
					<ProgressBar now={progress} label={<span>{`${progress} % Completato`}</span>} />

					<div className="question">

						{area}

						<Row>
							<Col xs="12" md="12" className="text">
								<button onClick={props.openModal} className={ (modal==="") ? "hide" : "" }>
									<img src={lampadina} alt="lampadina"/>
								</button>
								<p dangerouslySetInnerHTML={{__html: question_text}}/>
							</Col>
							<Col xs="12" md="6" className="multimedia">
								{multimedia}
							</Col>
						</Row>

					</div>

					<ButtonGroup onClick={(event) => props.handleClickAnswer(event, question_id)} className="answergroup">
						<button value={1} className={buttonsStatus[0]}>{label[1]}</button>
						<button value={2} className={buttonsStatus[1]}>{label[2]}</button>
						<button value={3} className={buttonsStatus[2]}>{label[3]}</button>
					</ButtonGroup>
					
					{alert}

					<ButtonGroup className="navigationgroup">
						<button onClick={props.handleClickBack}>
							<FontAwesomeIcon icon={faChevronLeft} />
						</button>
						<button onClick={props.handleClickNext}>
							<FontAwesomeIcon icon={faChevronRight} />
						</button>
					</ButtonGroup>

					<Modal show={props.showModal} onHide={props.closeModal}>
					  <Modal.Header closeButton>
					  </Modal.Header>

					  <Modal.Body>
					    <div dangerouslySetInnerHTML={{__html: modal}}></div>
					  </Modal.Body>

					  <Modal.Footer>
					    <button className="ds-button" onClick={props.closeModal}>Ok!</button>
					  </Modal.Footer>
					</Modal>

				</Col>
			</Row>
		</Container>
	);

}

export default quiz;