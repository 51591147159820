import React from 'react';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import loading from '../../../assets/images/loading.gif';

import './Email.css';

const email = ( props ) => {

	let error = null;
	if (props.displayError) {
			error = <Alert variant="danger"> {props.errorMessage}</Alert>
		}

	return (
		<Container className="Email">
			<Row>
				<Col>

					<h2> Quiz Completato! </h2>

					<p> Per visualizzare i risultati del quiz, inserisci il codice di quattro cifre che ti abbiamo inviato alla seguente casella postale: <b>{props.email}</b>  </p>

					<input id="pin" type="text" placeholder="Esempio: abcd" value={props.pin} onChange={props.handleChangeForm}/>
					
					{props.verifyLoading ? 
					<img id="loadingIcon" src={loading} alt="Loading Icon"/>
					: 
					<button className="ds-button" onClick={props.handleVerifyPin} disabled={!props.pin}> Conferma </button>}

					{error}

				</Col>
			</Row>
		</Container>
	);

}

export default email;