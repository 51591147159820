import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import comefunziona1 from '../../assets/images/comefunziona1.png';
import comefunziona2 from '../../assets/images/comefunziona2.png';
import comefunziona3 from '../../assets/images/comefunziona3.png';

import './Company.css';

const company = ( props ) => (
    <Container className="Company">
		<Row>
		   	<Col xs="12" className="message">
		   		<p><b>AppForUp</b> è uno strumento per valutare il livello di competenze sociali e personali dei tuoi collaboratori. Scoprendo quali skills di chi lavora insieme a te possono essere migliorate, metterai un moto un processo virtuoso di crescita professionale. Tre semplici passi:</p>
		   	</Col>

		   	<Col xs="12" md="4" className="howitworks">
		   		<img src={comefunziona1} alt="Come funziona 1"/>
		   		<p> <span>1</span> Crea un account aziendale </p>
		   	</Col>

		   	<Col xs="12" md="4" className="howitworks">
		   		<img src={comefunziona2} alt="Come funziona 2"/>
		   		<p> <span>2</span>  Condividi il link ai tuoi dipendenti </p>
		   	</Col>

		   	<Col xs="12" md="4" className="howitworks">
		   		<img src={comefunziona3} alt="Come funziona 3"/>
		   		<p> <span>3</span> Scopri i risultati aggregati</p>
		   	</Col>

		   	<Col xs="12" className="calltoaction">
		   		<p> AppForUp è totalmente gratuito </p>
		   		<button className="ds-button" onClick={() => props.history.push("registrati")}> Registra la tua azienda </button>
		   	</Col>

		</Row>
	</Container>
);

export default company;