import React from 'react';
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import './Forms.css';

const forms = (props) => {

	let disableSubmit = true;
	if (
		props.nome
		&& props.cognome
		&& props.email
		&& props.repeatEmail
		&& props.password
		&& props.repeatPassword
		&& props.privacy
	) {
		disableSubmit = false;
	}

	let error = null;
	if (props.displayError) {
		error = <Alert variant="danger"> {props.errorMessage}</Alert>
	}

	const privacyLabel = <p>Ho letto e accetto l'<a href="/privacy" target="_blank"> Informativa sulla privacy </a></p>;

	return (
		<Container className="Forms">
			<Row>
				<Col>

					<h2> Questionario Completato! </h2>

					<p> I risultati sono pronti. Compila il seguente form per visualizzarli. </p>
					<p> * campi obbligatori </p>

					<Form onSubmit={props.handleSubmit}>

						<hr></hr>

						<span> I TUOI DATI </span>

						<Form.Group>
							<Form.Label>Nome*</Form.Label>
							<Form.Control id="nome" type="text" placeholder="" value={props.nome} onChange={props.handleChangeForm} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Cognome*</Form.Label>
							<Form.Control id="cognome" type="text" placeholder="" value={props.cognome} onChange={props.handleChangeForm} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Email*</Form.Label>
							<Form.Control id="email" type="email" placeholder="" value={props.email} onChange={props.handleChangeForm} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Ripeti Email*</Form.Label>
							<Form.Control id="repeatEmail" type="email" placeholder="" value={props.repeatEmail} onChange={props.handleChangeForm} />
						</Form.Group>


						<Form.Group>
							<Form.Label>Password*</Form.Label>
							<Form.Control id="password" type="password" placeholder="" value={props.password} onChange={props.handleChangeForm} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Ripeti Password*</Form.Label>
							<Form.Control id="repeatPassword" type="password" placeholder="" value={props.repeatPassword} onChange={props.handleChangeForm} />
						</Form.Group>


						<Form.Group>
							<Form.Label>Numero di telefono</Form.Label>
							<Form.Control id="telefono" type="number" placeholder="Esempio: 333 333333" value={props.telefono} onChange={props.handleChangeForm} />
						</Form.Group>

						<Form.Group>
							<Form.Label>CAP residenza</Form.Label>
							<Form.Control id="cap_residenza" type="number" placeholder="Esempio: 93100" value={props.cap_residenza} onChange={props.handleChangeForm} />
						</Form.Group>


					{/*	<Form.Group>
							<Form.Label>Attuale stato occupazionale</Form.Label>
							<Form.Control as="select" onChange={props.handleChangeForm} id="attualeStatoOccupazionale" defaultValue={'DEFAULT'} >
								<option value="DEFAULT" disabled>Scegli un opzione</option>
								<option value="occupato">Occupato/a</option>
								<option value="inoccupato">Inoccupato/a – disoccupato/a</option>
								<option value="inmobilita">In mobilità/in NASPI</option>
								<option value="rdc">Percettore di RdC</option>
								<option value="domestico">Lavoratore/lavoratrice domestico/a</option>
							</Form.Control>
						</Form.Group>
					*/}


						<Form.Group>
							<Form.Label>Se hai già avuto un'eperienza lavorativa, in quale settore?</Form.Label>
							<Form.Control as="select" onChange={props.handleChangeForm} id="occupazionePrecedente" defaultValue={'DEFAULT'} onChange={props.handleChangeForm} >
								<option value="DEFAULT" disabled>Scegli un opzione</option>
								<option value="Agricoltura">Agricoltura</option>
								<option value="Industria">Industria</option>
								<option value="Commercio">Commercio</option>
								<option value="Artigianato">Artigianato</option>
								<option value="Ristorazione">Ristorazione/alberghiero</option>
								<option value="CuraCasaPersona">Cura della casa, delle persone</option>
								<option value="altroEsperienza">Altro (indicare)</option>
							</Form.Control>
						</Form.Group>


						<Form-Group>
							<Form.Label>Altra esperienza lavorativa</Form.Label>
							<Form.Control id="altraEsperienzaInput" type="text" placeholder="In quale settore?" onChange={props.handleChangeForm}></Form.Control>
						</Form-Group>



						<Form.Group>
							<Form.Label>Mi piacerebbe seguire corsi nelle seguenti aree</Form.Label>
							<Form.Control as="select" onChange={props.selectChanged} id="corsiInteresse" defaultValue={'DEFAULT'} onChange={props.handleChangeForm} >
								<option value="DEFAULT" disabled>Scegli un opzione</option>
								<option value="Agricoltura">Lingue straniere (inglese, francese, spagnolo…)</option>
								<option value="Industria">Uso delle tecnologie digitali</option>
								<option value="Commercio">Tecniche di vendita, organizzazione attività commerciali</option>
								<option value="Artigianato">Cura delle persone (OSA, OSS…)</option>
								<option value="Ristorazione">Attività manuali (legno, ceramica, vetro…)</option>
								<option value="CuraCasaPersona">Contabilità, amministrazione, gestione aziendale</option>
								<option value="altroEsperienza">Soft skills (lavorare in gruppo, comunicazione…)</option>
								<option value="altroEsperienza">Autoimpresa (per aprire una propria attività)</option>
								<option value="altroEsperienza">Hotellerie e ristorazione</option>
								<option value="altroEsperienza">Altro</option>

							</Form.Control>
						</Form.Group>


						<hr></hr>

						<span> LA TUA AZIENDA </span>

						<Form.Group>
							<Form.Label>Nome azienda</Form.Label>
							<Form.Control id="nome_azienda" type="text" placeholder="Esempio: Rossi Srl" value={props.nome_azienda} onChange={props.handleChangeForm} />
						</Form.Group>

						<Form.Group>
							<Form.Label>CAP azienda</Form.Label>
							<Form.Control id="cap_azienda" type="number" placeholder="Esempio: 93100" value={props.cap_azienda} onChange={props.handleChangeForm} />
						</Form.Group>

						<hr></hr>

						<Form.Group>
							<Form.Check id="privacy" type="checkbox" label={privacyLabel} onChange={props.handleChangeForm} checked={props.privacy} />
						</Form.Group>


						{error}

						<button className="ds-button" onClick={props.handleSubmitForms} type="submit" disabled={disableSubmit}>
							Invia
						</button>
					</Form>

				</Col>
			</Row>
		</Container>
	);

}

export default forms;